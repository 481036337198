import {Component, ElementRef, Input, OnChanges} from '@angular/core';
import {toCamelCase} from '../../../utils/string.utils';

import {fal} from './fal.library';
import {far} from './far.library';
import {fas} from './fas.library';
import {fad} from './fad.library';

export type FaVariant = 'regular' | 'light' | 'solid' | 'duotone';

@Component({
    selector: 'fa, fal, far, fas, fad',
    styleUrls: ['./fa.component.scss'],
    template: `
        <svg *ngIf="faIcon" aria-hidden="true"
             [attr.dataPrefix]="tagName" [attr.dataIcon]="_icon" [attr.viewBox]="viewBox"
             [attr.class]="'svg-inline--fa fa-w-16' + (spin ? ' fa-spin' : '')"
             role="img" xmlns="http://www.w3.org/2000/svg">
            <path *ngFor="let path of svgPath; let f = first;" fill="currentColor" [style.opacity]="getPathOpacity(f)" [attr.d]="path"></path>
        </svg>
    `,
})
export class FaComponent implements OnChanges {
    @Input('i') icon: string;
    @Input() variant: FaVariant;
    @Input() spin = false;
    @Input() faIcon: any;

    tagName: string;
    _icon: string;

    constructor(elem: ElementRef) {
        this.tagName = elem.nativeElement.tagName.toLowerCase();
    }

    ngOnChanges() {
        if (this.icon) {
            this._icon = toCamelCase(`fa-${this.icon}`);
            if (this.tagName == 'fal' || this.variant == 'light') this.faIcon = fal[this._icon];
            else if (this.tagName == 'fas' || this.variant == 'solid') this.faIcon = fas[this._icon];
            else if (this.tagName == 'fad' || this.variant == 'duotone') this.faIcon = fad[this._icon];
            else this.faIcon = far[this._icon];
        }
    }

    getPathOpacity(first: boolean) {
        if (this.variant !== 'duotone' && this.tagName !== 'fad') return 1;
        return first ? 0.4 : 1;
    }

    get viewBox(): string {
        return `0 0 ${this.faIcon.icon.slice(0, 2).join(' ')}`;
    }

    get svgPath(): any[] {
        return Array.isArray(this.faIcon.icon[4]) ? this.faIcon.icon[4] : [this.faIcon.icon[4]];
    }
}
