import {
    faAddressBook,
    faAddressCard,
    faAllergies,
    faAlignJustify,
    faAmbulance,
    faArrowFromBottom,
    faArrowFromLeft,
    faArrowFromRight,
    faArrowFromTop,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faArrowsV,
    faArrowUp,
    faArrowToBottom,
    faArrowToLeft,
    faArrowToRight,
    faArrowToTop,
    faLongArrowLeft,
    faLongArrowRight,
    faLongArrowUp,
    faLongArrowDown,
    faBacteria,
    faBallotCheck,
    faBan,
    faBandAid,
    faBarcodeRead,
    faBarcodeScan,
    faBars,
    faBatteryBolt,
    faBatteryEmpty,
    faBatteryFull,
    faBatteryHalf,
    faBatteryQuarter,
    faBatterySlash,
    faBatteryThreeQuarters,
    faBed,
    faBell,
    faBirthdayCake,
    faBookOpen,
    faBoxAlt,
    faBoxOpen,
    faBoxFull,
    faBriefcase,
    faBuilding,
    faBullseye,
    faCalendar,
    faCalendarPlus,
    faCalendarEdit,
    faCalendarTimes,
    faCapsules,
    faCaretDown,
    faCaretLeft,
    faCaretUp,
    faCaretRight,
    faChartBar,
    faChartLine,
    faChartPie,
    faCheck,
    faCheckCircle,
    faCheckDouble,
    faCheckSquare,
    faChevronCircleDown,
    faChevronCircleLeft,
    faChevronCircleRight,
    faChevronCircleUp,
    faChevronDoubleDown,
    faChevronDoubleLeft,
    faChevronDoubleRight,
    faChevronDoubleUp,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircle,
    faCircleNotch,
    faClipboard,
    faClipboardCheck,
    faClipboardList,
    faClipboardListCheck,
    faClipboardPrescription,
    faClock,
    faClone,
    faCloudDownload,
    faCloudUpload,
    faCodeBranch,
    faCodeMerge,
    faCog,
    faCogs,
    faComment,
    faCommentLines,
    faComments,
    faCommentSlash,
    faCreditCard,
    faCross,
    faDatabase,
    faDiagnoses,
    faDisease,
    faDollarSign,
    faDolly,
    faDotCircle,
    faEdit,
    faEllipsisH,
    faEllipsisHAlt,
    faEllipsisV,
    faEllipsisVAlt,
    faEnvelope,
    faEnvelopeOpenText,
    faEquals,
    faNotEqual,
    faExclamation,
    faExchange,
    faExclamationCircle,
    faExclamationSquare,
    faExclamationTriangle,
    faExternalLink,
    faEye,
    faEyeSlash,
    faFarm,
    faFax,
    faFemale,
    faFile,
    faFileAlt,
    faFileExcel,
    faFileExport,
    faFileArchive,
    faFileCode,
    faFileCsv,
    faFileImage,
    faFilePdf,
    faFileInvoiceDollar,
    faFileUpload,
    faFilter,
    faFlask,
    faFolder,
    faFolderOpen,
    faFolderTree,
    faGamepad,
    faGlasses,
    faHandHoldingUsd,
    faHandHoldingWater,
    faHandHoldingMedical,
    faHashtag,
    faHeadSideBrain,
    faHeart,
    faHeartbeat,
    faHdd,
    faHistory,
    faHospital,
    faHospitalUser,
    faHourglass,
    faIdBadge,
    faInbox,
    faInfo,
    faInfoCircle,
    faKey,
    faLeafHeart,
    faLock,
    faLockOpen,
    faLink,
    faUnlink,
    faList,
    faMap,
    faMapMarker,
    faMapMarkerCheck,
    faMapMarkerEdit,
    faMapMarkerPlus,
    faMapSigns,
    faMale,
    faMegaphone,
    faMicrophone,
    faMinus,
    faMobile,
    faMoneyBillWave,
    faNewspaper,
    faNotesMedical,
    faPaperPlane,
    faPause,
    faPenAlt,
    faPencil,
    faPencilAlt,
    faPhone,
    faPills,
    faPlay,
    faPlus,
    faPlusCircle,
    faPlusSquare,
    faPowerOff,
    faPrescriptionBottle,
    faPrescription,
    faPrint,
    faObjectGroup,
    faQuestion,
    faQuestionCircle,
    faQuoteRight,
    faReceipt,
    faRedo,
    faRepeat,
    faSave,
    faSearch,
    faSearchDollar,
    faSearchMinus,
    faSearchPlus,
    faSignIn,
    faSignOut,
    faShare,
    faShareAlt,
    faSlidersH,
    faSparkles,
    faSquare,
    faStar,
    faStarExclamation,
    faStarShooting,
    faStethoscope,
    faStickyNote,
    faStop,
    faStopCircle,
    faStopwatch,
    faSync,
    faSyringe,
    faTable,
    faTablet,
    faTabletAndroidAlt,
    faTachometer,
    faTag,
    faTags,
    faTasks,
    faThermometer,
    faThermometerThreeQuarters,
    faTh,
    faThLarge,
    faThList,
    faTimes,
    faTimesCircle,
    faTint,
    faToolbox,
    faTombstone,
    faTrash,
    faTrashAlt,
    faTruck,
    faUser,
    faUserMd,
    faUserPlus,
    faUsers,
    faUsersMedical,
    faUserSecret,
    faUserTag,
    faUserTimes,
    faWheelchair,
    faVenusMars,
    faVial,
    faVials,
    faWallet,
    faWhistle,
    faWifi,
    faWindowMinimize,
    faWrench,
} from '../../fortawesome/pro-regular-svg-icons';
import {faHtml5} from '../../fortawesome/fontawesome-free-brands';

export const far = {
    faAddressBook,
    faAddressCard,
    faAllergies,
    faAlignJustify,
    faAmbulance,
    faArrowFromBottom,
    faArrowFromLeft,
    faArrowFromRight,
    faArrowFromTop,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faArrowsV,
    faArrowUp,
    faArrowToBottom,
    faArrowToLeft,
    faArrowToRight,
    faArrowToTop,
    faLongArrowLeft,
    faLongArrowRight,
    faLongArrowUp,
    faLongArrowDown,
    faBacteria,
    faBallotCheck,
    faBan,
    faBandAid,
    faBarcodeRead,
    faBarcodeScan,
    faBars,
    faBatteryBolt,
    faBatteryEmpty,
    faBatteryFull,
    faBatteryHalf,
    faBatteryQuarter,
    faBatterySlash,
    faBatteryThreeQuarters,
    faBed,
    faBell,
    faBirthdayCake,
    faBookOpen,
    faBoxAlt,
    faBoxOpen,
    faBoxFull,
    faBriefcase,
    faBuilding,
    faBullseye,
    faCalendar,
    faCalendarPlus,
    faCalendarEdit,
    faCalendarTimes,
    faCapsules,
    faCaretDown,
    faCaretLeft,
    faCaretUp,
    faCaretRight,
    faChartBar,
    faChartLine,
    faChartPie,
    faCheck,
    faCheckCircle,
    faCheckDouble,
    faCheckSquare,
    faChevronCircleDown,
    faChevronCircleLeft,
    faChevronCircleRight,
    faChevronCircleUp,
    faChevronDoubleDown,
    faChevronDoubleLeft,
    faChevronDoubleRight,
    faChevronDoubleUp,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircle,
    faCircleNotch,
    faClipboard,
    faClipboardCheck,
    faClipboardList,
    faClipboardListCheck,
    faClipboardPrescription,
    faClock,
    faClone,
    faCloudDownload,
    faCloudUpload,
    faCodeBranch,
    faCodeMerge,
    faCog,
    faCogs,
    faComment,
    faCommentLines,
    faComments,
    faCommentSlash,
    faCreditCard,
    faCross,
    faDatabase,
    faDiagnoses,
    faDisease,
    faDollarSign,
    faDolly,
    faDotCircle,
    faDownload: faArrowToBottom,
    faEdit,
    faEllipsisH,
    faEllipsisHAlt,
    faEllipsisV,
    faEllipsisVAlt,
    faEnvelope,
    faEnvelopeOpenText,
    faEquals,
    faNotEqual,
    faExclamation,
    faExchange,
    faExclamationCircle,
    faExclamationSquare,
    faExclamationTriangle,
    faExternalLink,
    faEye,
    faEyeSlash,
    faFarm,
    faFax,
    faFemale,
    faFile,
    faFileAlt,
    faFileExcel,
    faFileExport,
    faFileArchive,
    faFileCode,
    faFileCsv,
    faFileImage,
    faFilePdf,
    faFileInvoiceDollar,
    faFileUpload,
    faFilter,
    faFlask,
    faFolder,
    faFolderOpen,
    faFolderTree,
    faGamepad,
    faGlasses,
    faHandHoldingUsd,
    faHandHoldingWater,
    faHandHoldingMedical,
    faHashtag,
    faHeadSideBrain,
    faHeart,
    faHeartbeat,
    faHdd,
    faHistory,
    faHospital,
    faHospitalUser,
    faHourglass,
    faHtml5,
    faIdBadge,
    faInbox,
    faInfo,
    faInfoCircle,
    faKey,
    faLeafHeart,
    faLock,
    faLockOpen,
    faLink,
    faUnlink,
    faList,
    faMap,
    faMapMarker,
    faMapMarkerCheck,
    faMapMarkerEdit,
    faMapMarkerPlus,
    faMapSigns,
    faMale,
    faMegaphone,
    faMicrophone,
    faMinus,
    faMobile,
    faMoneyBillWave,
    faNewspaper,
    faNotesMedical,
    faPaperPlane,
    faPause,
    faPenAlt,
    faPencil,
    faPencilAlt,
    faPhone,
    faPlay,
    faPills,
    faPlus,
    faPlusCircle,
    faPlusSquare,
    faPowerOff,
    faPrescriptionBottle,
    faPrescription,
    faPrint,
    faObjectGroup,
    faQuestion,
    faQuestionCircle,
    faQuoteRight,
    faReceipt,
    faRedo,
    faRepeat,
    faSave,
    faSearch,
    faSearchDollar,
    faSearchMinus,
    faSearchPlus,
    faSignIn,
    faSignOut,
    faShare,
    faShareAlt,
    faSlidersH,
    faSparkles,
    faSquare,
    faStar,
    faStarExclamation,
    faStarShooting,
    faStethoscope,
    faStickyNote,
    faStop,
    faStopCircle,
    faStopwatch,
    faSync,
    faSyringe,
    faTable,
    faTablet,
    faTabletAndroidAlt,
    faTachometer,
    faTag,
    faTags,
    faTasks,
    faThermometer,
    faThermometerThreeQuarters,
    faTh,
    faThLarge,
    faThList,
    faTimes,
    faTimesCircle,
    faTint,
    faToolbox,
    faTombstone,
    faTrash,
    faTrashAlt,
    faTruck,
    faUser,
    faUserMd,
    faUserPlus,
    faUsers,
    faUsersMedical,
    faUserSecret,
    faUserTag,
    faUserTimes,
    faWheelchair,
    faVenusMars,
    faVial,
    faVials,
    faWallet,
    faWhistle,
    faWifi,
    faWindowMinimize,
    faWrench,
};
